import axios from "axios";
import config from "../config/index";

axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.headers['Access-Control-Allow-Origin'] ='*';
axios.defaults.headers['Access-Control-Allow-Methods'] ='*';


if (localStorage.token) {
    axios.defaults.headers.common["apiKey"] = JSON.parse(localStorage.token);
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const err = error.response.request.responseURL;
        const fromUrl = err.split("/").pop();
        const nonRedirectUrls = ["login", "forgot-password", "reset-password"];

        if (error.response.status === 401 && !nonRedirectUrls.includes(fromUrl)) {
            window.location.href = "/login";
            localStorage.clear();
        }

        throw error;
    }
);

export default axios;