import Vue from 'vue'
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        dark: false,
        theme: {
            dark: false,
            themes: {
                light: {
                    primary: colors.purple,
                    secondary: colors.grey.lighten1,
                    accent: colors.shades.black,
                    error: colors.red.accent3,
                    background:'rgb(37, 150, 190)',
                    default:'#56D2FB'
                },
                dark: {
                    primary: colors.blue.lighten3,
                    default:'#56D2FB'
                },
            }
        }
    }
}

export default new Vuetify(opts);