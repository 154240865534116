import axios from "axios";

export const state = {
    users: []
};

export const getters = {
    users: state => state.users,
};

export const mutations = {
    SET_USERS(state, data) {
        state.users = data;
    },

    ADD_USER(state, data) {
        state.users = [...state.users, data];
    },

    UPDATE_USER(state, data) {
        const {id} = data;
        const users = state.users;
        const index = users.findIndex((item) => item.id === id);

        const editedItem = {...users[index], ...data};
        users.splice(index, 1, editedItem);
        state.users = users;
    },

    DELETE(state, data) {
        const users = state.users;
        const index = users.findIndex((item) => item.id === data.id);
        users.splice(index, 1);

        state.users = users;
    }
};

export const actions = {
    async getUsers({commit, state}) {
        try {
            const {data} = await axios.get("/users/get");
            const users = data.userList;

            if (users) {
                commit('SET_USERS', users);
            }

            return users;
        } catch (error) {
            console.error(error);
        }
    },

    async createUser({commit, state}, formData) {
        const {data} = await axios.post("/users/create", formData);
        const {user} = data;

        if (user) {
            commit('ADD_USER', user);
        }

        return user;
    },

    async updateUser({commit, state}, formData) {
        const {data} = await axios.put("/users/edit", formData);

        if (data.user) {
            commit('UPDATE_USER', data.user);
        }

        return data.user;
    },

    async deleteUser({commit, state}, id) {
        try {
            const data = await axios.delete(`/users/delete/${id}`);

            commit('DELETE', id);

            return data;
        } catch (e) {
            console.error(e);
        }
    },

//    APP CONFIG
    async config({commit, state}, formData) {
        const {data} = await axios.post(`/admin/config`, formData);
        return data;
    },
};