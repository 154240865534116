import Vue from 'vue'
import App from './app.vue'
import router from './router/index'
import store from './store'
import vuetify from "./plugins/vuetify";
import Vuelidate from 'vuelidate';
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "./plugins/axios";
import myPlugin from "./plugins/global-functions";
import VueExcelEditor from 'vue-excel-editor'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueToast);
Vue.use(myPlugin);
Vue.use(VueExcelEditor)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
