import axios from "axios";

export const state = {
    typeOfEnterprise: [],
    data: {},
    defaultValues: {
        "employees":{"employed":150,"fired":100}, //(wartość z kalkulatora)

        "quits": { //% wszystkich odejść w danym roku
            productionWorker: 9, //L14
            customerService: 12, //L15
            expert: 17, //L16
            menager: 19, //L17
            director: 21, //L18
            otherWorkers: 22 //L19
        },

        "externalRecruitment": { //Ilość rekrutacji zewnętrznych (prowadzonych przez agencje rekrutacyjne) (L43:L48)
            productionWorker: 0, //L43
            customerService: 0, //L44
            expert: 1, //L45
            menager: 0, //L46
            director: 0, //L47
            otherWorkers: 0 //L48
        },

        "averageSalary": { //Średnia pensja  wraz z benefitami Brutto
            productionWorker: 3300, //L5
            customerService: 6600, //L6
            expert: 8800, //L7
            menager: 6600, //L8
            director: 13000, //L9
            otherWorkers: 4400 //L10
        },

        "noticePeriod": {  //Długość okresu wypowiedzenia osób odchodzących w %
            twoWeeks: 50, //L22
            oneMonth: 20, //L23
            threeMonths: 30 //L24
        },

        "employeeIntroduction": { //Ile dni trwa onboarding (od 1 dnia pracy do samodzielności)
            productionWorker: 30, //L27
            customerService: 70, //L28
            expert: 90, //L29
            menager: 70, //L30
            director: 90, //L31
            otherWorkers: 60 //L32
        },

        //współczynniki rotacji (C5:H23)

        //wiersz 5
        "dismissal": { //Decyzja o odejściu/zwolnieniu pracownika
            productionWorker: 8,//wartośc przedstawione w godzinach // =(M8/160)* dismissal['productionWorker']
            customerService: 8, // =(M8/160)*dismissal['customerService']
            expert: 8, // =(M9/160)*dismissal['expert']
            menager: 16, // =(M9/160)*dismissal['menager']
            director: 32, // =(M9/160)*dismissal['director']
            otherWorkers: 8, // =(M8/160)*dismissal['otherWorkers']
        },

        //wiersz 6
        "timeToFarewell": {//Rozmowa z odchodzącym pracownikiem; czas osoby odchodzącej, jak również zespołu poświęcony na pożegnania i rozmowy o zmianie pracy.
            productionWorker: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10},//employeeTime - czas poświęcony odchodzącemu pracownikowi(5dni).
            customerService: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10}, //otherEmployeesTime - czas poświęcony przez zespół pozostałych pracowników.
            expert: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10},//partOfEmployees - np. 10% z ilości zatrudnionych pracowników w firmie.
            menager: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10},
            director: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10},
            otherWorkers: {"employeeTime": 40, "otherEmployeesTime": 4, "partOfEmployees": 10},
        },

        //wiersz 7
        "administrativeTasks": {//Czynności administracyjne (świadectwo pracy, obiegówka itp.)
            productionWorker: 4,//time - czas pracy w godzinach =($M$5/160)* administrativeTasks['productionWorker']
            customerService: 4,//=($M$5/160)* administrativeTasks['customerService']
            expert: 4,
            menager: 4,
            director: 4,
            otherWorkers: 4,
        },

        //wiersz 9
        "decreaseInProductivity": {//Spadek produktywności w okresie wypowiedzenia (m.in.. spadek zaangażowania odchodzącego)
            productionWorker: {"productivity": 50},//productivity - oznacza procętową wartośc zaangarzowania odchodzącego pracownika
            customerService: {"productivity": 50}, //=decreaseInProductivity['customerService']['productivity']%*($M6*N23-D$8)
            expert: {"productivity": 50},
            menager: {"productivity": 50,},
            director: {"productivity": 50},
            otherWorkers: {"productivity": 50},
        },

        //wiersz 10
        "severancePay": {//Odprawa, ekwiwalenty (np. za niewykorzystany urlop) itp.
            productionWorker: 5, //days - oznacza ilość dni niewykorzystanego urlopu
            customerService: 5, // =M6*(severancePay['customerService']/20)
            expert: 25, // =M7*(severancePay['expert']/20)
            menager: 5,
            director: 25,
            otherWorkers: 5,
        },

        //wiersz 11
        "jobAdvertisementCost": {//koszt przygotowania i emisji ogłoszeń o pracę,
            productionWorker: 1000,//koszt przygotowania, opracowania i publikacji ogłoszenia o pracę
            customerService: 1500,
            expert: 2000,
            menager: 1500,
            director: 2500,
            otherWorkers: 1500,
        },

        //wiersz 12
        "candidateSelection": { //czas potrzebny na selekcję napływających aplikacji,
            productionWorker: 32,//time - czas pracy w godzinach
            customerService: 40, // =($M$10/160)*candidateSelection['customerService']
            expert: 80,
            menager: 53,
            director: 80,
            otherWorkers: 40,
        },

        //wiersz 13
        "interview": {//czas potrzebny na rozmowy kwalifikacyjne
            productionWorker: 26.6,//time - czas pracy w godzinach
            customerService: 32, //=SUMA($M10;$M8)*(interview['customerService']/160)
            expert: 40,
            menager: 32,
            director: 40,
            otherWorkers: 32,
        },

        //wiersz 14
        "referenceCheck": 8, // =($M$10/160)*referenceCheck //czas potrzebny na sprawdzenie referencji, uzgodnienie warunków z wybraną osobą oraz przygotowanie umowy.

        //wiersz 15
        "costOfTesting": {//Koszt testów/innych metod płatnych
            productionWorker: 0,//koszt przygotowania, opracowania i publikacji ogłoszenia o pracę
            customerService: 50,
            expert: 300,
            menager: 200,
            director: 1000,
            otherWorkers: 50,
        },

        //wiersz 16
        "healthAndSafetyTraining": 200,//Szkolenie BHP

        //wiersz 17
        "medicalChecks": 300, //Badanie lekarskie

        //wiersz 18
        "workstationCost": {//Koszty dostosowania stanowiska (dostępy IT, licencje, sprzęt, wizytówki, czas pracy IT, administracja,ubranie robocze, środki ochronne)
            productionWorker: 800,//koszt przygotowania, opracowania i publikacji ogłoszenia o pracę
            customerService: 1000,
            expert: 2000,
            menager: 2000,
            director: 3000,
            otherWorkers: 1000,
        },

        //wiersz 19
        "employeeInductionTime": 80, //=($M5/160)*employeeInductionTime
        // czas jaki osoba poświęci na zapoznanie się z firmą, obowiązkami i procedurami. - wartość podana w godzinach

        //wiersz 20
        "trainingNewEmployee": { //=ŚREDNIA($M$5;M8;$M$10)/ trainingNewEmployee['days'] *$L27* trainingNewEmployee['otherEmployeesTime']%
            // czas innych pracowników potrzebny na przeszkolenie nowej osoby,
            days: 20, otherEmployeesTime: 40
        },

        //wiersz 22
        "productivityAtTheBeginning": { //=($L27/20*$M5-C$19)*productivityAtTheBeginning['productivity']%
            //Niepełna produktywność na początku pracy nowej osoby
            productivity: 50//wartość wyrażona w %
        },

        //wiersz 23
        "agencyCost": 20, //=$L43*( agencyCost /100)*12*$L5
        //Koszt agencji rekrutacyjnych //20% rocznego wynagrodzenia brutto
    }
};

export const getters = {
    getDefaultValues: state => state.defaultValues,
    typeOfEnterprise: state => state.typeOfEnterprise,
    quits: state => state.data.quits,
    externalRecruitment: state => state.data.externalRecruitment,
    averageSalary: state => state.data.averageSalary,
    noticePeriod: state => state.data.noticePeriod,
    employeeIntroduction: state => state.data.employeeIntroduction,
    getData: state => state.data
};

export const mutations = {
    SET_DATA(state, data) {
        state.data = data;
    },

    UPDATE_ENTERPRISE_DATA(state, data) {
        state.typeOfEnterprise = data;
    },

    UPDATE_DATA(state, data) {
        state.defaultValues = data;
    },
};

export const actions = {
    async getDefault({commit, state}, type = 'default') {
        try {
            const {data} = await axios.get(`/api/getDefault/${type}`);

            if (data && data.code == 200) {
                commit('SET_DATA', data.defaultValues);
            }

            return data;
        } catch (error) {
            console.error(error);
        }
    },

    async setDefault({commit, state}, {formData, name=''}) {
        try {
            const {data} = await axios.post(`/admin/setDefault/${name}`, formData);

            if (data && data.code == 200) {
                commit('SET_DATA', data.defaultValues);
            }

            return data;
        } catch (error) {
            console.error(error);
        }
    },

    async getDefaultlist({commit, state}) {
        try {
            const {data} = await axios.get("/getDefaultlist");

            if (data && data.code == 200) {
                commit('UPDATE_ENTERPRISE_DATA', data.list);
            }

            return data;

        } catch (error) {
            console.error(error);
        }
    },

    async deleteDefaultValues({commit, state}, name) {
        try {
            const {data} = await axios.delete(`/admin/delete/${name}`);

            return data;

        } catch (error) {
            console.error(error);
        }
    }
};
