<template>
  <v-system-bar height="50" color="deep-purple accent-4" dense dark app>
    <v-app-bar-nav-icon v-if="showButton" @click="$emit('drawer',true)"></v-app-bar-nav-icon>

    <img src="@/assets/logo.png" height="35"/>

    <v-spacer></v-spacer>

    <v-btn to="/account" link icon>
      <v-avatar
          color="warning lighten-2"
          size="35"
      >{{ getFirstChar(user.firstname) }}{{ getFirstChar(user.lastname) }}
      </v-avatar>
    </v-btn>
  </v-system-bar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    showButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods: {
    getFirstChar(str) {
      return str.charAt(0);
    }
  },

  data() {
    return {}
  },
}
</script>