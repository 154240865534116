<template>
  <v-app>
    <v-main>
      <v-container class="fill-height justify-center" fluid>
        <v-card class="auth-card" style="" outlined>
          <slot/>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  components: {},
  props: {},

  data() {
    return {};
  },

  watch: {},
  computed: {},
  methods: {},
  mounted() {
  }

};
</script>
<style lang="scss" scope>
.letter-spacing-0 {
  letter-spacing: 0 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

@media (max-width: 420px) {
  .auth-card {
    width: 100% !important;
  }
}

.auth-card {
  width: 400px;
  min-height: 400px;
  overflow: hidden;
}

.auth-card.signup {
  width: 750px;
}

.transition-wrapper {
  position: relative;
}
</style>