<template>
  <div>
    <v-navigation-drawer
        v-if="mobile"
        class="background accent-4"
        v-model="show"
        fixed
        temporary
        dark
    >
      <v-list>
        <div v-for="item in links">
          <v-list-group
              v-if="item.role.includes(user.roles) && item.subLinks"
              :key="item.title"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="sublink in item.subLinks"
                :to="sublink.path"
                :key="sublink.title"
            >
              <v-list-item-title>{{ sublink.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>

          <v-list-item
              v-if="item.role.includes(user.roles) && !item.subLinks"
              :key="item.title"
              :to="item.path"
              link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :key="item.text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn to="/logout" color="red" block>
            Wyloguj się
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
        v-else
        class="background accent-4"
        dark
        app
    >
      <v-list>
        <div v-for="item in links">


          <v-list-group
              v-if="item.role.includes(user.roles) && item.subLinks"
              :key="item.title"
              color="white"
              no-action
              link
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="sublink in item.subLinks"
                :to="sublink.path"
                :key="sublink.title"
                color="white"
            >
              <v-list-item-title>{{ sublink.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>


          <v-list-item
              v-if="item.role.includes(user.roles) && !item.subLinks"
              :key="item.title"
              :to="item.path"
              link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :key="item.text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn to="/logout" color="red" block>
            Wyloguj się
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    },

    links: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    show(e) {
      if (!e) {
        this.$emit('drawer', false);
        this.show = true;
      }
    }
  },

  data() {
    return {
      show: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }

}
</script>