import axios from "axios";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async generatePdf({commit, state}, formData) {
        try {
            const response = await axios.post(`/api/generatePdf`, formData);

            return response;
        } catch (error) {
            console.error(error);
        }
    }
};