<template>
  <v-app>
    <v-main>
      <v-container class="fill-height justify-center" fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  components: {},
  props:{},

  data() {
    return {};
  },

  watch: {},
  computed:{},
  methods: {},
  mounted() {}

};
</script>
<style lang="scss" scope></style>