import store from '../store/index';
import config from '@/config';

const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import('../views/HomeView'),
    //     meta: {layout: "default", authRequired: true}
    // },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/Users'),
        meta: {layout: "default", authRequired: true, roles: ['admin']},
        children: []
    },
    {
        path: "/account",
        name: "account-me",
        component: () => import("../views/Account"),
        meta: {layout: "default", authRequired: true},
    },
    {
        path: '/configurator',
        name: 'configurator',
        component: () => import('../views/Configurator/Index'),
        meta: {layout: "default", authRequired: true, roles: ['admin']},
    },
    {
        path: '/coefficients',
        name: 'coefficients',
        component: () => import('../views/Configurator/Coefficients'),
        meta: {layout: "default", authRequired: true, roles: ['admin']},
    },
    {
        path: '/',
        name: 'calculator',
        component: () => import('../views/Calculator'),
        meta: {layout: "default", authRequired: true},
        children: []
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/Settings'),
        meta: {layout: "default", authRequired: true, roles: ['admin']},
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logout');
                location.href = config.publicPath + 'login';
            }
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login'),
        meta: {
            layout: "auth",
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({name: 'calculator'});
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/auth/ResetPassword'),
        meta: {
            layout: "auth",
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({name: 'calculator'});
                } else {
                    next();
                }
            }
        },
        children: [
            {
                path: "/reset-password/:token/:email/:time",
                name: "reset-password-code",
                component: () => import("../views/auth/ResetPassword"),
                meta: {layout: "auth"},
            }
        ]
    },
    {
        path: "/*",
        name: "error",
        component: () => import("../views/PageNotFound"),
        meta: {
            layout: store.getters['auth/loggedIn'] ? "default" : "error",
            authRequired: false
        }
    }
];

export default routes
