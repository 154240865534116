<template>
  <v-app id="inspire">
    <NavBar @drawer="(e)=>drawer = !drawer" :showButton="windowWidth < 1264"/>
    <Drawer v-if="drawer && windowWidth < 1264" :links="links" :mobile="drawer" @drawer="(e)=>drawer = false"/>
    <Drawer v-else :links="links" :mobile="false"/>

    <v-main class="grey lighten-3">
      <v-container
          class="py-8 px-6"
          fluid
      >
        <v-row>
          <v-col cols="12">
            <slot/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "@/components/layout/Drawer";
import NavBar from "@/components/layout/NavBar";

export default {
  components: {
    NavBar,
    Drawer
  },
  props: {},

  data() {
    return {
      drawer: false,
      windowWidth: window.innerWidth,
      links: [
        // {title: 'Dashboard', path: '/', icon: 'mdi-desktop-mac-dashboard', role: ['admin', 'user']},
        {title: 'Kalkulator', path: '/', icon: 'mdi-calculator', role: ['admin', 'user']},
        {
          title: 'Konfigurator', icon: 'mdi-view-dashboard', role: ['admin'],
          subLinks: [
            {
              title: 'Domyślne wartości',
              path: '/configurator'
            },

            {
              title: 'Współczynniki',
              path: '/coefficients'
            }
          ]
        },
        {title: 'Użytkownik', path: '/account', icon: 'mdi-account-group', role: ['user']},
        {title: 'Użytkownicy', path: '/users', icon: 'mdi-account-group', role: ['admin']},
        {title: 'Ustawienia', path: '/settings', icon: 'mdi-cog', role: ['admin']},
      ],
    };
  },

  watch: {},
  computed: {},
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

};
</script>
<style lang="scss">
.maxWidth900 {
  max-width: 900px;
}
</style>