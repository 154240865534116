import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import routes from './routes';
import config from '../config/index';

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    base: config.publicPath,
    mode: 'history',
    linkActiveClass: 'active'
});

router.beforeEach((routeTo, routeFrom, next) => {
    const authRequired = routeTo.matched.some(route => route.meta.authRequired);
    const routerRoles = routeTo.meta.roles ?? [];

    if (routeFrom.name === 'login' && store.getters['auth/loggedIn']) {
        return next();
    }

    if (!authRequired) return next();

    if (store.getters['auth/loggedIn']) {
        const {roles:userRole} = store.getters['auth/user'];
        if (routerRoles.includes(userRole) || routerRoles.length === 0) {
            return next();
        }else{
            return next({name:'error'})
        }
    } else {
        return next({
            name: 'login',
            query: {redirectFrom: routeTo.path}
        });
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).

    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...

                        if (args.length) {
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

export default router;
