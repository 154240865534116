<template>
  <div id="app">
    <default-layout v-if="$route.meta.layout === 'default'">
      <router-view :key="$route.fullPath"/>
    </default-layout>
    <auth-layout v-if="$route.meta.layout === 'auth'">
      <router-view :key="$route.fullPath"/>
    </auth-layout>
    <error-layout v-if="$route.meta.layout === 'error'">
      <router-view :key="$route.fullPath"/>
    </error-layout>
  </div>
</template>

<script>
import DefaultLayout from "./layouts/main";
import AuthLayout from "./layouts/auth";
import ErrorLayout from "./layouts/error";

export default {
  metaInfo: {
    title: 'Kalkulator Rotacji',
    titleTemplate: `%s`,
    meta: [{name: 'description', content: 'description'}]
  },

  components: {
    DefaultLayout,
    AuthLayout,
    ErrorLayout
  },

  data() {
    return {
      authRoutes: ["login", "reset-password", "forget-password"]
    };
  }
};
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
.v-toast__text{
  font-size: .875rem!important;
  font-family: Roboto,sans-serif!important;
}
</style>
