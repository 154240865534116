import axios from "axios";

export const state = {
    user: getSavedState("user"),
    token: getSavedState("token"),
    loading: false
};

export const getters = {
    loggedIn(state) {
        return (
            state.user &&
            Object.keys(state.user).length > 0 &&
            Object.getPrototypeOf(state.user) === Object.prototype
        );
    },
    user: state => state.user,
};

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_USER(state, data) {
        const {email, roles, firstname, lastname, api_key} = data;
        const user = {email, roles, firstname, lastname};

        saveState("user", user);
        saveState("token", api_key);

        state.user = user;
    },

    UPDATE_USER(state, data) {
        const {email, roles, firstname, lastname} = data;
        const user = {email, roles, firstname, lastname};

        saveState("user", user);

        state.user = user;
    },

    LOGOUT(state, newValue) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
};

export const actions = {
    async logout({commit}) {
        commit("LOGOUT", null);
    },

    async validate({commit, state}) {
        if (localStorage.token) {
            axios.defaults.headers.common["apiKey"] = JSON.parse(localStorage.token);
        }
    },

    async login({commit, dispatch, getters}, {username, password} = {}) {
        const {data} = await axios.post("/login", {
            username,
            password
        });

        if (data.user) {
            commit("SET_USER", data.user);
            dispatch("validate");
        }

        return data.user;
    },

    async callPassword({commit, state}, {email}) {
        await axios.post("/callPassword", {email});
    },

    async resetPassword({commit, state}, {token, email, time, password}) {
        await axios.put(`/resetPassword`, {
            password, token, email ,time
        });
    },

};

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}